<dynamic-type-input *ngFor="let property of properties"
                    [label]="property.displayName"
                    [metadata]="metadata"
                    [metadataProviderType]="metadataProviderType"
                    [inputData]="{ dataType: property.dataType, possibleValues: property.possibleValues }"
                    [inputType]="detectInputType(property.dataType, property.possibleValues)"
                    [value]="currentNode.configuration.parameters[property.pathType + '.' + property.name] || ''"
                    (valueChange)="handleParameterChange(property.pathType, property.name, $event)" />

<dynamic-type-input *ngFor="let link of links"
                    [label]="link.displayName"
                    [metadataProviderType]="metadataProviderType"
                    [metadata]="metadata"
                    [inputData]="{ expectedInputTypeMetadata: link.metadata }"
                    [inputType]="'COMBOBOX'"
                    [value]="currentNode.configuration.parameters[link.pathType + '.' + link.name] || ''"
                    (valueChange)="handleParameterChange(link.pathType, link.name, $event)" />
