import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList, TemplateRef, ViewChild } from '@angular/core';

import { DataType, MetadataProviderType, ResourceTypeMetadata, ResourceTypePropertyPossibleValue } from '@shared/domain';
import { InputType } from '@shared/helpers';

import { DynamicResourceComboComponent, ResourceEntityProvider } from '@shared/components/inputs/dynamic-resource-combo';
import { PossibleValuesComboComponent } from '@shared/components/inputs/possible-values-combo';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { DateTimeInputComponent } from '@shared/components/inputs/date-time-input/date-time-input.component';
import { RobawsNgSwitchComponent } from '@ui/robaws-ng-switch/robaws-ng-switch.component';
import { TooltipModule } from 'primeng/tooltip';
import { CalendarModule } from 'primeng/calendar';
import { DateRangeInputComponent } from '@shared/components/inputs/date-range-input/date-range-input.component';
import { DateInputComponent } from '@shared/components/inputs/date-input/date-input.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { NgModelChangeDebouncedDirective } from '@ui/ng-model-change-debounced.directive';
import { RobawsNgTemplateDirective } from '@shared/components/robaws-ng-template.directive';
import { Nullable } from 'primeng/ts-helpers';
import { BasicInputComponent } from '@shared/components/inputs/basic-input/basic-input.component';
import { ResourceRefInputComponent } from '@shared/components/inputs/resource-ref-input/resource-ref-input.component';
import { NumberRangeInputComponent } from '@shared/components/inputs/number-range-input/number-range-input.component';

export type InputData = {
  dataType?: DataType;
  expectedInputTypeMetadata?: ResourceTypeMetadata;
  possibleValues?: ResourceTypePropertyPossibleValue[];
};

@Component({
  selector: 'dynamic-type-input',
  templateUrl: 'dynamic-type-input.component.html',
  standalone: true,
  imports: [
    FormsModule,
    NgSelectModule,
    AsyncPipe,
    PossibleValuesComboComponent,
    DynamicResourceComboComponent,
    InputTextModule,
    DateTimeInputComponent,
    RobawsNgSwitchComponent,
    TooltipModule,
    CalendarModule,
    DateRangeInputComponent,
    DateInputComponent,
    NgTemplateOutlet,
    OverlayPanelModule,
    NgModelChangeDebouncedDirective,
    BasicInputComponent,
    RobawsNgTemplateDirective,
    ResourceRefInputComponent,
    NumberRangeInputComponent,
  ],
})
export class DynamicTypeInputComponent implements AfterContentInit {
  @Input({ required: true })
  public metadataProviderType: MetadataProviderType;

  @Input({ required: true })
  public metadata: ResourceTypeMetadata;

  @Input({ required: true })
  public inputType: InputType;

  @Input()
  public inputData: InputData;

  @Input()
  public value?: string | undefined | null;

  @Input()
  public label?: string | undefined | null;

  @Input()
  public floatingLabel = true;

  @Input()
  public allowClear = true;

  @Input()
  public openInDropdown = false;

  @Input()
  public resourceEntityProvider: ResourceEntityProvider | undefined;

  @Output()
  public valueChange: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild(DynamicResourceComboComponent)
  protected dynamicResourceCombo: DynamicResourceComboComponent;

  protected inputTemplate: TemplateRef<any> | undefined;
  @ContentChildren(RobawsNgTemplateDirective)
  private templates: Nullable<QueryList<RobawsNgTemplateDirective>>;

  public ngAfterContentInit(): void {
    if (this.templates) {
      this.templates.forEach((template) => {
        if (template.type === 'input') {
          this.inputTemplate = template.template;
        }
      });
    }
  }

  public onValueChange(value: string | undefined | null): void {
    this.value = value || '';
    this.valueChange.emit(this.value);
  }

  public clear(): void {
    if (this.dynamicResourceCombo) {
      this.dynamicResourceCombo.clear();
    }
  }
}
