@if (this.isLoaded()) {
  <div class="view-controls">
    <view-tabs [viewContentType]="this.viewContentType"
               [metadata]="this.metadata"
               (tabChange)="onTabChange($event)"/>
  </div>

  <div class="view-header">
    <view-filters *ngIf="this.currentView"
                  [viewId]="this.currentView.id"
                  [viewContentType]="this.viewContentType"
                  [metadata]="this.metadata"
                  [viewFilterGroup]="this.currentView.filterGroup"
                  [additionalFilters]="this.currentView.additionalFilters"
                  [overrideFilters]="this.overrideFilters"
                  [searchQuery]="this.searchText"
                  (searchQueryChanged)="this.onSearchTextUpdate($event)"
                  (filtersChanged)="this.onFiltersSaved($event)"
                  (overrideFiltersCleared)="this.onOverrideFiltersCleared()"/>

    <div class="view-header-buttons" *ngIf="this.currentView">
      <div class="view-display-buttons" *ngIf="this.isAnalyticsEnabled() || this.isCalendarEnabled() || this.isFinancialsEnabled()">
        <mat-icon (click)="this.switchToTable()"
                  fontIcon="list"
                  class="view-type-icon table-icon"
                  tooltipPosition="top"
                  [pTooltip]="'table' | translate"
                  [attr.data-active]="this.currentView.type === 'TABLE'"/>

        <mat-icon *ngIf="this.isFinancialsEnabled()"
                  (click)="this.switchToFinancials()"
                  fontIcon="euro_symbol"
                  class="view-type-icon table-icon"
                  tooltipPosition="top"
                  [pTooltip]="'financials' | translate"
                  [attr.data-active]="this.currentView.type === 'FINANCIALS'"/>

        <mat-icon *ngIf="this.isAnalyticsEnabled()"
                  (click)="this.switchToAnalytics()"
                  fontIcon="bar_chart"
                  class="view-type-icon table-icon"
                  tooltipPosition="top"
                  [pTooltip]="'analytics' | translate"
                  [attr.data-active]="this.currentView.type === 'ANALYTICS'"/>

        <mat-icon *ngIf="this.isCalendarEnabled()"
                  (click)="this.switchToCalendar()"
                  fontIcon="date_range"
                  class="view-type-icon table-icon"
                  tooltipPosition="top"
                  [pTooltip]="'calendar' | translate"
                  [attr.data-active]="this.currentView.type === 'CALENDAR'"/>
      </div>

      <mat-icon (click)="this.openSettingsDialog()"
                fontIcon="settings"
                class="settings-icon"/>
    </div>
  </div>

  @if (this.currentView && this.currentView.type === 'TABLE') {
    @if (this.viewLoading) {
      <p-table [value]="placeholderRows" [tableStyle]="{ 'min-width': '50rem' }" class="placeholder-table">
        <ng-template pTemplate="header">
          <tr>
            <th>
              <p-skeleton/>
            </th>
            <th>
              <p-skeleton/>
            </th>
            <th>
              <p-skeleton/>
            </th>
            <th>
              <p-skeleton/>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-i>
          <tr>
            <td>
              <p-skeleton/>
            </td>
            <td>
              <p-skeleton/>
            </td>
            <td>
              <p-skeleton/>
            </td>
            <td>
              <p-skeleton/>
            </td>
          </tr>
        </ng-template>
      </p-table>
    } @else if (this.viewColumns.length === 0) {
      <div class="empty-view">

        <div>
          <p>{{ 'overviews.no-columns' | translate }}</p>

          <button class="add-quick-filter robaws-ng-muted-button" (click)="this.openSettingsDialog()">
            <mat-icon fontIcon="settings"/>
            {{ 'overviews.change-columns' | translate }}
          </button>
        </div>
      </div>
    } @else {
      <p-table [lazy]="true"
               [metaKeySelection]="true"
               [resizableColumns]="true"
               [lazyLoadOnInit]="false"
               [loading]="dataLoading"
               [columns]="this.viewColumns"
               [value]="this.currentRows"
               [rows]="this.currentView.pageSize"
               [totalRecords]="this.totalRows"
               [multiSortMeta]="this.sorts"
               [(selection)]="this.selectedItems"
               (onLazyLoad)="this.loadViewData($event)"
               (onColResize)="this.onColumnResize()"
               (wheel)="this.onScroll()"
               columnResizeMode="expand"
               selectionMode="multiple"
               sortMode="multiple"
               scrollHeight="100%"
               #table>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th class="p-frozen-column" style="width: 4rem" pResizableColumn>
              <p-tableHeaderCheckbox/>
            </th>

            @for (column of columns; track column) {
              @if (column.sortable) {
                <th [pSortableColumn]="column.path"
                    [style]="column.width ? column.width + 'px' : ''"
                    pResizableColumn>
                  <div class="p-title">
                    <div class="p-title-content">
                      <div>{{ column.name }}</div>
                    </div>
                    <p-sortIcon class="p-title-sorter" *ngIf="column.sortable" [field]="column.path"></p-sortIcon>
                  </div>
                </th>
              } @else {
                <th pResizableColumn>
                  <div class="p-title">
                    <div class="p-title-content">
                      <div>{{ column.name }}</div>
                    </div>
                  </div>
                </th>
              }
            }
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
          <tr [pSelectableRow]="rowData"
              [pSelectableRowIndex]="rowIndex"
              (dblclick)="onRowDoubleClick($event, rowData)"
              [attr.style]="(rowData.color ? 'background: ' + rowData.color + '; ' : '') + 'color: ' + rowData.textColor + ';'"

              dynamic-overview-table-row [rowData]="rowData"
              [columns]="currentView.columns"
              [rowIndex]="rowIndex"
              [resourceType]="resourceType"
              [metadataPaths]="metadataPaths"
              (onSelectClick)="this.checkRangeSelect($event[0], $event[1])"
          ></tr>
        </ng-template>
      </p-table>

      <p-contextMenu *ngIf="table.el && !isTouchDevice()"
                     [target]="table.el.nativeElement"
                     [model]="this.contextMenuItems">

        <ng-template pTemplate="item" let-item>
          <a pRipple class="flex align-items-center p-menuitem-link">
            <mat-icon [fontIcon]="item.icon" [ngStyle]="item.iconStyle"/>
            <span class="ml-2" style="line-height: 24px;">{{ item.label }}</span>
          </a>
        </ng-template>
      </p-contextMenu>

      <dynamic-overview-paginator [totalItems]="this.totalRows"
                                  [pageSize]="this.currentView.pageSize"
                                  [page]="this.currentPage"
                                  (pageChange)="this.loadViewPage($event)"
                                  (pageSizeChange)="this.onPageSizeChange($event)"/>
    }
  }

  <view-settings-dialog [view]="this.currentView"
                        [paths]="this.metadataPaths"
                        [metadata]="this.metadata"
                        (settingsChanged)="this.onViewSettingsChanged($event)"
                        (viewDeleted)="this.onViewDeleted()"/>
}
