import { Injectable } from '@angular/core';
import { filter, fromEventPattern, map, Observable } from 'rxjs';

const INTERNAL_SERVICE_MESSAGE_EVENT_TYPE = 'internal-service-message';

export class InternalServiceMessageEvent extends Event {
  constructor(
    public service: string,
    public targetService: string,
    public action: string,
    public data: any,
  ) {
    super(INTERNAL_SERVICE_MESSAGE_EVENT_TYPE);

    this.data = JSON.stringify(data);
  }
}

@Injectable({
  providedIn: 'platform',
})
export class InternalServiceMessageService {
  private readonly messageListener: Observable<any>;

  constructor() {
    this.messageListener = fromEventPattern(
      (handler) => document.addEventListener(INTERNAL_SERVICE_MESSAGE_EVENT_TYPE, handler),
      (handler) => document.removeEventListener(INTERNAL_SERVICE_MESSAGE_EVENT_TYPE, handler),
    );
  }

  public onMessageReceive(service: string): Observable<InternalServiceMessageEvent> {
    return this.messageListener
      .pipe(map((obj) => obj as InternalServiceMessageEvent))
      .pipe(filter((it) => it.service === service && it.targetService === service));
  }

  public send(event: InternalServiceMessageEvent): void {
    document.dispatchEvent(event);
  }

  public dispatch(action: string, data: any): void {
    this.send(new InternalServiceMessageEvent('robaws-ng', 'robaws', action, data));
  }
}
