@if (this.metadataWithPath) {
  <property-path-selector [metadataProviderType]="'VIEW'"
                          [data]="this.metadataWithPath"
                          [showLabel]="false"
                          [filter]="this.filterAdditionalViewDataPaths"
                          (propertySelected)="this.handlePathSelected($event)"/>


  <robaws-operator-selector [metadataProviderType]="'VIEW'"
                            [metadataWithPath]="this.metadataWithPath"
                            [operator]="this.viewFilter.operator ?? 'EQUALS'"
                            (operatorChanged)="this.handleOperatorSelected($event)"
                            [attr.data-operation-input-data-loading]="this.operationInputDataLoading || !currentOperationComponent"/>


  <ndc-dynamic class="operation-settings"
               *ngIf="!this.operationInputDataLoading && currentOperationComponent"
               [ndcDynamicComponent]="currentOperationComponent"
               [ndcDynamicInjector]="this.injector"
               [ndcDynamicInputs]="{
                     data: {
                         metadataProviderType: 'VIEW',
                         metadata: this.currentResourceTypeMetadata,
                         value: this.viewFilter.value,
                         inputData: { dataType: this.currentDataType, expectedInputTypeMetadata: this.expectedMetadata, possibleValues: this.possibleValues },
                         inputType: this.currentInputType,
                         showLabel: false,
                         resourceEntityProvider: this.resourceEntityProvider.bind(this)
                     }
                 }"
               [ndcDynamicOutputs]="this.outputHandlers"
               (ndcDynamicCreated)="this.onComponentCreated($event)"/>
}
