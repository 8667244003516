<ng-container [ngTemplateOutlet]="this.inputTemplate || defaultInputTemplate"
              [ngTemplateOutletContext]="{ $implicit: this.onInputClick }"/>

<ng-template #defaultInputTemplate let-onClick>
  <div class="number-range" (click)="onClick($event)">
    @if (this.value) {
      <span>{{ this.minValue ?? 0 }} - {{ this.maxValue ?? 0 }}</span>
    } @else {
      <span class="select-number-range">{{ 'number-range-input.select-number-range' | translate }}</span>
    }
  </div>
</ng-template>

<p-overlayPanel #numberRangePickerPanel>
  <div class="range-picker">
    <p-inputNumber [useGrouping]="false"
                   [placeholder]="'min' | translate"
                   [minFractionDigits]="decimal ? 2 : 0"
                   [maxFractionDigits]="decimal ? 5 : 0"
                   [ngModel]="minValue"
                   (ngModelChangeDebounced)="this.onMinValueChange($event)"
                   [ngModelChangeDebounceTime]="1000"/>

    -

    <p-inputNumber [useGrouping]="false"
                   [placeholder]="'max' | translate"
                   [minFractionDigits]="decimal ? 2 : 0"
                   [maxFractionDigits]="decimal ? 5 : 0"
                   [(ngModel)]="maxValue"
                   (ngModelChangeDebounced)="this.onMaxValueChange($event)"
                   [ngModelChangeDebounceTime]="1000"/>
  </div>
</p-overlayPanel>
