<div class="search">
  <input type="text"
         [placeholder]="'search-placeholder' | translate"
         class="search-bar"
         [ngModel]="this.searchQuery"
         (ngModelChangeDebounced)="this.onSearchQueryUpdate($event)"
         pInputText/>

  <mat-icon *ngIf="this.searchQuery && this.searchQuery.length > 0"
            (click)="this.clearSearch()"
            fontIcon="close"
            class="clear-icon"/>
</div>

@if (this.viewFilterGroup) {
  <div class="quick-filters">
    @for (path of quickFilterPaths; track path) {
      <view-quick-filter [viewContentType]="this.viewContentType"
                         [metadata]="this.metadata"
                         [path]="path"
                         [additionalFilters]="this.additionalFilters"
                         (additionalFiltersChanged)="this.onAdditionalFiltersChanged($event)"/>
    }
  </div>

  <div class="advanced-filters-container">
    <button (click)="this.editAdvancedFilters()" [attr.data-filters]="this.amountOfFilters" class="robaws-ng-muted-button advanced-filters">
      <mat-icon fontIcon="tune"/>

      <span>
        @if (this.amountOfFilters > 0) {
          {{ 'overviews.quickfilters.advanced-filters-with-amount' | translate: {amountOfFilters: this.amountOfFilters} }}
        } @else {
          {{ 'overviews.quickfilters.advanced-filters' | translate }}
        }
      </span>

      <mat-icon *ngIf="this.amountOfFilters > 0"
                fontIcon="close_small"
                class="clear-icon"
                (click)="this.clearAll($event)"/>
    </button>
  </div>

  <p-sidebar position="right"
             [(visible)]="editingAdvancedFilters"
             [style]="{'min-width': 'min(600px, 100vw)', 'width': '35vw'}"
             (onHide)="this.save()">
    <ng-template pTemplate="header">
      <span class="font-semibold text-xl">{{ 'overviews.quickfilters.advanced-filters' | translate }}</span>
    </ng-template>

    <div class="deleted-archived-filters">
      <div class="deleted-filter">
        <p-checkbox [binary]="true"
                    [label]="'overviews.filters.show-deleted-items' | translate"
                    [ngModel]="this.showDeleted"
                    (ngModelChange)="this.toggleDeleted($event)"/>
      </div>

      <div class="archived-filter">
        <p-checkbox [binary]="true"
                    [label]="'overviews.filters.show-archived-items' | translate"
                    [ngModel]="this.showArchived"
                    (ngModelChange)="this.toggleArchived($event)"/>
      </div>
    </div>

    @if (this.overrideFilters) {
      <div class="override-filters-container">
        <div class="operator-switcher" *ngIf="this.overrideFilters.length > 0">
          <span class="operator-switcher-border"></span>

          <p class="operator">
            <span>{{ 'or' | translate }}</span>
          </p>
        </div>

        <div class="override-filters">
          @for (overrideFilter of overrideFilters; track overrideFilter) {
            <div class="override-filter-editor">
              <view-filter-editor [viewContentType]="this.viewContentType"
                                  [metadata]="this.metadata"
                                  [viewFilter]="overrideFilter"/>

              <delete-icon [askConfirmation]="false"
                           (deleteAccepted)="this.deleteOverrideFilter(overrideFilter)"
                           class="delete-icon"/>
            </div>
          }
        </div>
      </div>

      @if (this.overrideFilters.length > 0) {
        <div class="filter-group-divider">
          <hr/>
          <span>{{ this.viewFilterGroup.operator.toLowerCase() | translate }}</span>
          <hr/>
        </div>
      }
    }

    <view-filter-group-editor [viewContentType]="this.viewContentType"
                              [metadata]="this.metadata"
                              [viewFilterGroup]="this.viewFilterGroup"
                              [isParentFilterGroup]="true"
                              (clearAllClicked)="this.onClearAllClicked()"/>
  </p-sidebar>
}
