import { Path } from '@shared/services';
import { detectInputTypeFromPath, InputType } from '@shared/helpers';
import { RobawsOperator } from '@app/robaws/domain';
import { DataType } from '@shared/domain';

export function detectInputTypeFromPathAndRobawsOperator(path: Path, robawsOperator: RobawsOperator): InputType {
  const inputType = detectInputTypeFromPath(path);

  if (robawsOperator === 'IS_BETWEEN' || robawsOperator === 'IS_NOT_BETWEEN') {
    if (path.dataType === DataType.DATE) {
      return 'DATE_RANGE';
    } else if (path.dataType === DataType.NUMBER || path.dataType === DataType.DECIMAL) {
      return 'NUMBER_RANGE';
    }
  }

  if (robawsOperator === 'IS_ONE_OF' || robawsOperator === 'IS_NOT_ONE_OF') {
    if (inputType === 'COMBOBOX') {
      return 'MULTISELECT_COMBOBOX';
    } else if (inputType === 'POSSIBLE_VALUES') {
      return 'MULTISELECT_POSSIBLE_VALUES';
    }
  }

  if (inputType === 'POSSIBLE_VALUES') {
    if (robawsOperator === 'EQUALS' || robawsOperator === 'NOT_EQUALS') {
      return 'POSSIBLE_VALUES';
    } else {
      return 'STRING';
    }
  }

  return inputType;
}
