<div class="p-float-label">
  <input
    [value]="titleSignal()" (input)="onTitleInputChange($event)"
    pInputText
    type="text"/>
  <label>{{ 'nodes.create-robaws-notification.title' | translate }}</label>
</div>

<div class="p-float-label">
  <input
    [value]="contentSignal()" (input)="onContentInputChange($event)"
    pInputText
    type="text"/>
  <label>{{ 'nodes.create-robaws-notification.content' | translate }}</label>
</div>

<div class="custom">
  <p class="custom-placeholder">{{ 'nodes.create-robaws-notification.userIds' | translate }}</p>
  <ng-select class="custom-ngselect" (open)="userSearchText$.next('')"
             [ngModel]="currentNode.configuration.userIds"
             (ngModelChange)="onUsersChanged($event)"
             [items]="selectedUsers$ | async"
             [loading]="usersLoading"
             [multiple]="true"
             bindLabel="label"
             bindValue="value"
             groupBy="group"/>
</div>
