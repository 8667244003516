import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { View } from '@app/robaws/domain';
import { RobawsNgDialogComponent } from '@ui/robaws-ng-dialog/robaws-ng-dialog.component';
import { Path } from '@shared/services';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { DeleteIconComponent } from '@ui/delete-icon/delete-icon.component';
import { NgIf } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';
import { ViewSettingsComponent, ViewSettingsDTO } from '@app/robaws/components/dynamic-overview/view-settings/view-settings.component';
import { ResourceTypeMetadata } from '@shared/domain';

@Component({
  selector: 'view-settings-dialog',
  templateUrl: 'view-settings-dialog.component.html',
  standalone: true,
  imports: [
    RobawsNgDialogComponent,
    CheckboxModule,
    FormsModule,
    InputTextModule,
    CdkDrag,
    CdkDropList,
    MatIcon,
    TranslateModule,
    DeleteIconComponent,
    NgIf,
    TooltipModule,
    ViewSettingsComponent,
  ],
})
export class ViewSettingsDialogComponent {
  @Input({ required: true })
  public view: View;
  @Input({ required: true })
  public paths: Path[];
  @Input({ required: true })
  public metadata: ResourceTypeMetadata;
  @Output()
  public settingsChanged = new EventEmitter<ViewSettingsDTO>();
  @Output()
  public viewDeleted = new EventEmitter<View>();

  @ViewChild('dialog')
  protected dialog: RobawsNgDialogComponent;

  @ViewChild(ViewSettingsComponent)
  protected viewSettingsComponent: ViewSettingsComponent;

  public openDialog(): void {
    this.dialog.openDialog();
  }

  public closeDialog(): void {
    this.dialog.closeDialog();
  }

  protected onHide(): void {
    this.settingsChanged.emit(this.viewSettingsComponent.getCurrentSettings());
  }

  protected onViewDeleted(event: View): void {
    this.closeDialog();
    this.viewDeleted.emit(event);
  }
}
