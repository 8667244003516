@if (this.canDeleteView()) {
  <mat-icon class="visibility-icon"
            fontIcon="public"
            tooltipPosition="top"
            [pTooltip]="'overviews.settings.visibility.' + this.visibility.toLowerCase() | translate"
            [attr.data-visibility-type]="this.visibility"
            (click)="this.toggleVisibility()"/>

  <delete-icon class="delete-view"
               [askConfirmation]="true"
               (deleteAccepted)="this.deleteView()"/>
}

<div class="view-name">
  <p>{{ 'name' | translate }}</p>

  <input type="text"
         [(ngModel)]="this.name"
         pInputText/>
</div>

<div class="column-configurator">

  <h3>{{ 'overviews.settings.columns.list' | translate }}</h3>

  <mat-accordion class="column-list"
                 [cdkDropListData]="selectedColumns"
                 (cdkDropListDropped)="onDrop($event)"
                 cdkDropList
                 #columnScrollPanel>

    @for (column of selectedColumns; track column.path) {
      <mat-expansion-panel class="path-panel"
                           [expanded]="this.expandedIndex === $index"
                           hideToggle
                           cdkDrag>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon class="path-icon">drag_indicator</mat-icon>
            {{ column.displayName }}
          </mat-panel-title>

          <delete-icon [askConfirmation]="true"
                       (deleteAccepted)="removeSelectedColumn($index)"/>
        </mat-expansion-panel-header>
        <property-path-selector [metadataProviderType]="'VIEW'"
                                (propertySelected)="handlePropertySelect(column, $event)"
                                [data]="{ metadata: this.metadata, path: column.path }"
                                [includeLinks]="true"
                                [fetchDeep]="true"
                                [appendToBody]="true"
                                [spreadComplexTypes]="true"
                                [includeComplexTypes]="true"
                                [allowSelectableLinks]="false"
                                [filter]="this.filterAdditionalViewFilterPaths"/>
      </mat-expansion-panel>
    }
  </mat-accordion>

  <button class="add-column robaws-ng-muted-button" (click)="this.addColumn()">
    <mat-icon fontIcon="add"/>
    {{ 'overviews.settings.columns.add' | translate }}
  </button>
</div>
