<div *ngFor="let availableOperator of availableOperators;"
     class="operation"
     [attr.data-has-settings-opened]="isSelected(availableOperator.operator) && availableOperator.needsValue ? 'true' : 'false'">

  <span (click)="handleOperatorSelection(availableOperator.operator)"
        [class]="(isSelected(availableOperator.operator) ? 'selected ' : '') + 'operation-name'">
    <mat-icon fontIcon="arrow_circle_right" textPrimaryColor></mat-icon>

    <span>{{ 'operation.' + availableOperator.operator | translate }}</span>
  </span>

  @if (isSelected(availableOperator.operator) && availableOperator.needsValue) {
    <ndc-dynamic class="operation-settings"
                 [ndcDynamicComponent]="this.getOperationBuilderComponentForOperator(availableOperator.operator)"
                 [ndcDynamicInjector]="this.injector"
                 [ndcDynamicInputs]="{
                     data: {
                         metadataProviderType: 'AUTOMATION',
                         metadata: this.currentResourceTypeMetadata,
                         value: this.data.value,
                         inputData: { dataType: this.currentPath?.dataType, expectedInputTypeMetadata: this.expectedMetadata, possibleValues: this.possibleValues },
                         inputType: availableOperator.inputType,
                     },
                 }"
                 [ndcDynamicOutputs]="this.outputHandlers"/>
  }
</div>

<div class="actions-row">
  <button (click)="this.cancelled.emit()"
          class="cancel-button robaws-ng-button"
          mat-button
          primaryColor>
    {{ 'cancel' | translate }}
  </button>

  <button (click)="this.submit()"
          class="next-button robaws-ng-button"
          mat-button
          primaryColor>
    {{ 'add' | translate }}
  </button>
</div>
