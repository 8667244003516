export function counts<T>(array: T[]): Map<T, number> {
  const counts = new Map<T, number>();

  for (const value of array) {
    counts.set(value, (counts.get(value) ?? 0) + 1);
  }

  return counts;
}

export function printXArrayElementsThenAbbreviate(inputArray: string[], numberOfElementsToPrint: number = 3): string {
  let arrayElementsAsString: string = '';
  if (inputArray.length < numberOfElementsToPrint) {
    inputArray.forEach((arrayElement) => (arrayElementsAsString += `${arrayElement}, `));
    return arrayElementsAsString.slice(0, -2).concat('.');
  }

  for (let i = 0; i < numberOfElementsToPrint; i++) {
    arrayElementsAsString += inputArray[i] + ', ';
  }
  return arrayElementsAsString + '+' + (inputArray.length - numberOfElementsToPrint) + '.';
}
