<div #treeSelectRoot class="tree-select-container">
  @if (allowMultipleValues) {
    <span class="p-float-label">
      <div class="tree-value-container" (click)="focusInput()">
        @if (this.showSelectedValues) {
          <ng-container *ngFor="let item of selectedValue">
            <span class="tree-value-item" (click)="focusInput()">
              <span (click)="remove(item.id)">×</span>
              {{ item.name }}
            </span>
          </ng-container>
        }
        <div class="tree-value-input">
          <input autocapitalize="off"
                 autocomplete="off"
                 class="keyword tree-select-input-field"
                 [id]="this.uid"
                 [ngModel]="this.searchText"
                 (focus)="this.showTreeSelectPanel($event)"
                 (ngModelChange)="onSearchTextInput($event)"
                 #treeSelectInput/>
        </div>
      </div>

      <label *ngIf="label && label.length > 0" [htmlFor]="this.uid">{{ label }}</label>
    </span>

    <mat-icon *ngIf="this.showClear && this.value?.length"
              class="icon clear"
              fontIcon="close_small"
              (click)="this.clear()"/>
  } @else {
    <span class="p-float-label">
      <input pInputText
             autocapitalize="off"
             autocomplete="off"
             class="tree-select-input-field"
             [id]="this.uid"
             [ngModel]="this.searchText"
             (focus)="this.showTreeSelectPanel($event)"
             (ngModelChange)="onSearchTextInput($event)"
             #treeSelectInput/>

      <label *ngIf="label && label.length > 0" [htmlFor]="this.uid">{{ label }}</label>
    </span>

    <mat-icon *ngIf="this.showClear && this.searchText && this.searchText.length > 0"
              class="icon clear"
              fontIcon="close_small"
              (click)="this.clear()"/>
  }

  @if (!this.inline) {
    <mat-icon class="icon expand-toggle"
              fontIcon="arrow_drop_down"
              (click)="panel!.overlayVisible ? panel!.hide() : this.showTreeSelectPanel($event)"/>
  }
</div>

@if (this.inline) {
  <ng-container [ngTemplateOutlet]="treeSelectContent"/>
} @else {
  <p-overlayPanel #treeSelectPanel
                  [appendTo]="this.appendTo && this.appendTo.length !== 0 ? this.appendTo : 'body'"
                  [style]="{'width': this.panelWidth + 'px'}"
                  styleClass="tree-select-overlay"
                  (onHide)="onHide()">
    <ng-container [ngTemplateOutlet]="treeSelectContent"/>
  </p-overlayPanel>
}

<ng-template #treeSelectContent>
  <div class="tree-select" *ngIf="this.tree">
    <tree-select-group *ngFor="let node of nodesWithChildren"
                       [group]="node"
                       [value]="value"
                       [tree]="this.tree"
                       (itemSelected)="onItemSelect($event); inputRef.nativeElement?.focus()"
                       (expandedChanged)="this.realignPanel()"/>
    <tree-select-item *ngFor="let node of nodesWithoutChildren"
                      [node]="node"
                      [value]="value"
                      [tree]="this.tree"
                      (itemSelected)="onItemSelect($event); inputRef.nativeElement?.focus()"/>

    @if (allowCustomValues && shouldShowCustomValueNode) {
      <tree-select-item [node]="getCustomValueNode!"
                        [tree]="this.tree"
                        [value]="value"
                        [isCustomValue]="true"
                        (itemSelected)="onSearchTextInput(''); onItemSelect($event); ; inputRef.nativeElement?.focus()"/>
    } @else if (availableItems.length === 0) {
      <div class="tree-value-empty">{{ 'ui.tree-select.no-items-found' | translate }}</div>
    }
  </div>
</ng-template>
