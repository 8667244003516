import { Component, DestroyRef, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges, Type } from '@angular/core';

import { DataType, MetadataWithPath, PathType, ResourceTypeMetadata, ResourceTypePropertyPossibleValue } from '@shared/domain';
import { Path, PathService } from '@shared/services';
import { DynamicResourceTypeProvider } from '@app/shared/services/dynamic-resource-type.provider';
import { getLinkOperators } from '@app/shared/domain/Operator';
import * as RobawsOperationBuilders from './builders';
import { detectInputTypeFromPath, InputType } from '@shared/helpers';
import { RobawsOperatorService } from '@app/robaws/services';
import { RobawsOperator } from '@app/robaws/domain/RobawsOperator';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { NgComponentOutlet, NgFor } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { DynamicComponent, DynamicIoModule } from 'ng-dynamic-component';
import { RobawsConstants } from '@app/robaws/domain/RobawsConstants';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TextPrimaryColorDirective } from '@ui/theme/text-primary-color.directive';
import { PrimaryColorDirective } from '@ui/theme/primary-color.directive';

type AvailableOperatorVO = {
  operator: RobawsOperator;
  needsValue: boolean;
  inputType: InputType;
};

export type RobawsOperationData = {
  operator?: RobawsOperator;
  value?: string;
};

@Component({
  selector: 'robaws-operation-builder',
  templateUrl: 'robaws-operation-builder.component.html',
  styleUrls: ['robaws-operation-builder.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    TranslateModule,
    NgFor,
    NgComponentOutlet,
    MatButtonModule,
    DynamicComponent,
    DynamicIoModule,
    TextPrimaryColorDirective,
    PrimaryColorDirective,
  ],
})
export class RobawsOperationBuilderComponent implements OnInit, OnChanges {
  @Input({ required: true })
  public data: MetadataWithPath & RobawsOperationData;

  @Output()
  public operationConfigured: EventEmitter<RobawsOperationData> = new EventEmitter<RobawsOperationData>();

  @Output()
  public cancelled: EventEmitter<void> = new EventEmitter<void>();

  protected availableOperators: AvailableOperatorVO[] = [];

  protected possibleValues: ResourceTypePropertyPossibleValue[];
  protected currentResourceTypeMetadata: ResourceTypeMetadata;
  protected expectedMetadata: ResourceTypeMetadata;
  protected outputHandlers = {
    valueChange: (value: unknown) => this.onValueChange(value as string),
  };
  protected currentPath?: Path;
  private dynamicResourceTypeProvider: DynamicResourceTypeProvider = new DynamicResourceTypeProvider('ROBAWS');

  constructor(
    protected injector: Injector,
    private robawsOperatorService: RobawsOperatorService,
    private pathService: PathService,
    private destroyRef: DestroyRef,
  ) {}

  public ngOnInit() {
    this.updatePath();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      this.updatePath();
    }
  }

  protected handleOperatorSelection(operator: RobawsOperator): void {
    this.data.operator = operator;
    this.data.value = undefined;

    // this will be handled in one of the parent views to re-align the overlay panel in case it doesn't fit anymore in the position it's currently at
    document.dispatchEvent(new CustomEvent(RobawsConstants.STANDALONE_FILTER_OVERLAY_UPDATE));
  }

  protected isSelected(operator: RobawsOperator): boolean {
    return this.data.operator === operator;
  }

  protected submit(): void {
    this.operationConfigured.emit({
      operator: this.data.operator,
      value: this.data.value,
    });
  }

  protected onValueChange(value: string): void {
    this.data.value = value;
  }

  protected getOperationBuilderComponentForOperator(operator: RobawsOperator): Type<any> {
    const operationBuilder = Object.values(RobawsOperationBuilders).find((builder) => {
      // @ts-ignore
      return builder.isSupported(operator);
    });

    if (!operationBuilder) {
      throw new Error(`Operation builder for operator ${operator} not found`);
    }

    return operationBuilder;
  }

  private updatePath(): void {
    this.pathService.getPath(this.dynamicResourceTypeProvider, this.data.metadata.name, this.data.path, true).subscribe((path) => {
      if (path) {
        this.currentPath = path;
        this.currentResourceTypeMetadata = path.parentMetadata;

        if (path.targetResourceType) {
          this.dynamicResourceTypeProvider.getMetadata(path.targetResourceType).subscribe((metadata) => {
            this.expectedMetadata = metadata;

            this.fetchAvailableOperations();
          });
        } else {
          this.fetchAvailableOperations();
        }
      } else {
        // path not found, cancelling
        this.cancelled.emit();
      }
    });
  }

  private fetchAvailableOperations() {
    if (!this.currentPath) {
      return;
    }

    if (this.currentPath.possibleValues && this.currentPath.possibleValues.length > 0) {
      this.possibleValues = this.currentPath.possibleValues;
    }

    if (this.currentPath.pathType === PathType.links) {
      this.availableOperators = getLinkOperators();
    } else {
      this.robawsOperatorService
        .getSupportedOperations(this.currentPath.dataType ?? DataType.TEXT)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((availableOperators) => {
          this.availableOperators = availableOperators.map((availableOperator) => ({
            operator: availableOperator.robawsOperator,
            needsValue: availableOperator.needsValue,
            inputType: detectInputTypeFromPath(this.currentPath!),
          }));

          document.dispatchEvent(new CustomEvent(RobawsConstants.STANDALONE_FILTER_OVERLAY_UPDATE));
        });
    }
  }
}
