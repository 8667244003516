import { DynamicResourceItem, MetadataProviderType, PageDTO } from '@shared/domain';
import { AUTOMATION_RESOURCE_TYPE_ENTITY_MAPPER, AutomationResourceTypeEntityService } from '@app/automation/services';
import { ROBAWS_RESOURCE_TYPE_ENTITY_MAPPER, RobawsResourceTypeEntityService } from '@app/robaws/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { inject, runInInjectionContext } from '@angular/core';
import { ResourceTypeEntityParams, ResourceTypeEntityProvider } from '@app/shared/services/resource-type-entity-provider';
import { AppInjector } from '@app/app.module';

export class DynamicResourceTypeEntityProvider implements ResourceTypeEntityProvider<DynamicResourceItem> {
  private robawsResourceTypeEntityService: RobawsResourceTypeEntityService;
  private automationResourceTypeEntityService: AutomationResourceTypeEntityService;

  constructor(private metadataProviderType: MetadataProviderType) {
    runInInjectionContext(AppInjector, () => {
      this.robawsResourceTypeEntityService = inject(RobawsResourceTypeEntityService);
      this.automationResourceTypeEntityService = inject(AutomationResourceTypeEntityService);
    });
  }

  public searchEntities(resourceType: string, params: ResourceTypeEntityParams): Observable<PageDTO<DynamicResourceItem>> {
    switch (this.metadataProviderType) {
      case 'AUTOMATION':
        return this.automationResourceTypeEntityService.searchEntities(resourceType, params).pipe(
          map((page) => {
            return {
              items: AUTOMATION_RESOURCE_TYPE_ENTITY_MAPPER.toDynamicResourceItems(page.items),
              size: page.size,
              currentPage: page.currentPage,
              totalPages: page.totalPages,
              totalItems: page.totalItems,
            };
          }),
        );
      case 'ROBAWS':
      case 'VIEW':
        return this.robawsResourceTypeEntityService.searchEntities(resourceType, params).pipe(
          map((page) => {
            return {
              items: ROBAWS_RESOURCE_TYPE_ENTITY_MAPPER.toDynamicResourceItems(page.items),
              size: page.size,
              currentPage: page.currentPage,
              totalPages: page.totalPages,
              totalItems: page.totalItems,
            };
          }),
        );
    }
  }

  public searchEntity(resourceType: string, id: string): Observable<DynamicResourceItem> {
    switch (this.metadataProviderType) {
      case 'AUTOMATION':
        return this.automationResourceTypeEntityService
          .searchEntity(resourceType, id)
          .pipe(map((entity) => AUTOMATION_RESOURCE_TYPE_ENTITY_MAPPER.toDynamicResourceItem(entity)));
      case 'ROBAWS':
      case 'VIEW':
        return this.robawsResourceTypeEntityService
          .searchEntity(resourceType, id)
          .pipe(map((entity) => ROBAWS_RESOURCE_TYPE_ENTITY_MAPPER.toDynamicResourceItem(entity)));
    }
  }
}
