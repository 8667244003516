import { ChangeDetectionStrategy, Component, computed, EventEmitter, Input, input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { ViewDataRowWithTextColor } from '@app/robaws/components/dynamic-overview/dynamic-overview.component';
import { ViewColumn } from '@app/robaws/domain';
import { ViewDataRowColumn } from '@app/robaws/domain/ViewDataRowColumn';
import { DataType } from '@shared/domain';
import { Path, UrlService } from '@shared/services';
import { TranslateService } from '@ngx-translate/core';

type NavigationDetails = {
  resourceType: string;
  id: string;
};

type Column = Omit<ViewDataRowColumn, 'parentId'> & {
  navigationDetails?: NavigationDetails;
};

type RowData = ViewDataRowWithTextColor & {
  columns: Column[];
};

@Component({
  selector: '[dynamic-overview-table-row]',
  templateUrl: 'dynamic-overview-table-row.component.html',
  styleUrls: ['dynamic-overview-table-row.component.scss'],
  standalone: true,
  imports: [CommonModule, TableModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicOverviewTableRowComponent {
  @Input({ required: true })
  public resourceType: string;
  public rowData = input<ViewDataRowWithTextColor | undefined>(undefined);
  public metadataPaths = input<Path[] | undefined>(undefined);
  public rowIndex = input<number | undefined>(undefined);
  public columns = input<ViewColumn[] | undefined>(undefined);

  @Output()
  public onSelectClick: EventEmitter<[MouseEvent, number]> = new EventEmitter<[MouseEvent, number]>();

  sortedRowData = computed<RowData | undefined>(() => {
    const rowData = this.rowData();
    const columns = this.columns();
    const paths = this.metadataPaths();
    if (!rowData || !columns || !paths) {
      return undefined;
    }
    const sorted: Column[] = [];
    for (const column of columns) {
      const path = paths.find((p) => p.path === column.dataPath);
      const dataType = path?.dataType ?? DataType.TEXT;

      const columnData = rowData.columns.find((c) => {
        return c.columnId === column.id || c.columnPath === column.dataPath;
      });

      let navigationDetails: NavigationDetails | undefined = undefined;
      if ((path?.primary ?? false) && (path?.depth === 0 || !!columnData?.parentId)) {
        navigationDetails = {
          resourceType: path?.parentMetadata.name ?? this.resourceType,
          // If depth is 0, navigate to self
          id: columnData?.parentId ?? rowData.id,
        };
      }

      sorted.push({
        columnPath: column.dataPath,
        columnId: column.id,
        value: this.parseValue(columnData?.value, dataType),
        navigationDetails,
      });
    }
    return {
      id: rowData.id,
      columns: sorted,
      color: rowData.color,
      textColor: rowData.textColor,
    };
  });

  constructor(
    private translateService: TranslateService,
    private urlService: UrlService,
  ) {}

  parseValue(value: any, dataType: DataType): string {
    if (value === undefined || value === null || value === '') {
      return '';
    }

    if (dataType === DataType.DATE || dataType === DataType.DATE_TIME) {
      const date = new Date(value);

      if (dataType === DataType.DATE) {
        return date.toLocaleDateString();
      } else {
        return date
          .toLocaleString(undefined, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: undefined,
          })
          .replace(/,/g, '');
      }
    } else if (dataType === DataType.BOOLEAN && typeof value === 'boolean') {
      return value ? this.translateService.instant('yes') : this.translateService.instant('no');
    } else {
      return value;
    }
  }

  // fix for shift selecting rows while clicking on the checkbox (see: https://github.com/primefaces/primeng/issues/5496)
  checkRangeSelect(event: MouseEvent, index?: number) {
    if (index === undefined) {
      return;
    }
    this.onSelectClick.emit([event, index]);
  }

  navigate(column: Column): void {
    if (!column.navigationDetails) {
      return;
    }
    this.urlService.navigateToResourceType(column.navigationDetails.resourceType, column.navigationDetails.id);
  }
}
