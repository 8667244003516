import { Component, EventEmitter, Input, OnInit, Output, signal, ViewEncapsulation } from '@angular/core';
import { PathType } from '@shared/domain';
import { AutomationResourceTypeMetadata, NodeDTO } from '@app/automation/domain';
import { CreateRobawsNotificationNode } from '@app/automation/domain/nodes/CreateRobawsNotificationNode';
import { catchError, concat, debounceTime, distinctUntilChanged, forkJoin, map, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { PathService, UserService } from '@shared/services';
import { DynamicResourceTypeProvider } from '@app/shared/services/dynamic-resource-type.provider';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'create-robaws-notification-configurer',
  templateUrl: 'create-robaws-notification-node-configurer.component.html',
  styleUrls: ['create-robaws-notification-node-configurer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CreateRobawsNotificationNodeConfigurerComponent implements OnInit {
  @Output()
  public onTitleChange: EventEmitter<string | null> = new EventEmitter<string | null>();

  @Output()
  public onContentChange: EventEmitter<string | null> = new EventEmitter<string | null>();

  @Output()
  public onUsersChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  @Input()
  public currentNode: NodeDTO<CreateRobawsNotificationNode>;

  @Input()
  public resourceTypeMetadata: AutomationResourceTypeMetadata;

  titleSignal = signal<string>('');
  contentSignal = signal<string>('');
  public selectedUsers$: Observable<AvailableUser[]>;
  private dynamicResourceTypeProvider = new DynamicResourceTypeProvider('AUTOMATION');
  public userSearchText$ = new Subject<string>();
  public usersLoading = false;

  constructor(
    private userService: UserService,
    private translateService: TranslateService,
    private pathService: PathService,
  ) {}

  public ngOnInit(): void {
    this.titleSignal.set(this.currentNode.configuration.title ?? '');
    this.contentSignal.set(this.currentNode.configuration.content ?? '');
    this.loadUsers();
  }

  private async loadUsers() {
    const userGroup = this.translateService.instant('user');
    this.selectedUsers$ = this.pathService.getPaths(this.dynamicResourceTypeProvider, this.resourceTypeMetadata.name, true).pipe(
      map((paths) => paths.filter((path) => path.pathType == PathType.links).filter((path) => path.targetResourceType == 'user')),
      switchMap(() => {
        let defaultItems: Observable<AvailableUser[]> | undefined;

        if (this.currentNode.configuration.userIds.length !== 0) {
          const observables = this.currentNode.configuration.userIds.map((userId: string) =>
            this.userService.findById(userId).pipe(map((user) => new AvailableUser(user.id, user.fullName, userGroup))),
          );
          defaultItems = forkJoin(observables);
        }

        return concat(
          defaultItems ?? of([]),
          this.userSearchText$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            tap(() => (this.usersLoading = true)),
            switchMap((term) =>
              this.userService
                .getUsers(term, 0, 100)
                .pipe(
                  map((it) => {
                    const items: AvailableUser[] = [];
                    items.push(...it.items.map((user) => new AvailableUser(user.id, user.fullName, userGroup)));
                    this.onUsersChange.emit(it.items.map((user) => user.id));
                    return items;
                  }),
                )
                .pipe(
                  catchError(() => of([])), // empty list on error
                  tap(() => (this.usersLoading = false)),
                ),
            ),
          ),
        );
      }),
    );
  }

  onTitleInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.titleSignal.set(input.value);
    this.onTitleChange.emit(input.value);
  }

  onContentInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.contentSignal.set(input.value);
    this.onContentChange.emit(input.value);
  }

  onUsersChanged(event: string[]): void {
    this.currentNode.configuration.userIds = event;
    this.onUsersChange.emit(event);
  }
}

class AvailableUser {
  constructor(
    public value: string,
    public label: string,
    public group: string,
  ) {}
}
