export function replaceLastOccurrence(str: string, search: string, replaceWith: string): string {
  const index = str.lastIndexOf(search);

  if (index < 0) {
    return str;
  }

  return str.substring(0, index) + replaceWith + str.substring(index + search.length);
}

export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function isEmpty(str: string): boolean {
  return str === null || str === undefined || str.length === 0;
}

export function isBlank(str: string): boolean {
  return isEmpty(str) || str.trim().length === 0;
}

export function abbreviateTextWithEllipsis(input: string, maxLength: number = 30): string {
  if (input.length < maxLength) {
    return input;
  }
  return input.substring(0, maxLength).concat(' …');
}
