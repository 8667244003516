import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { TranslateModule } from '@ngx-translate/core';
import { RobawsNgTemplateDirective } from '@shared/components/robaws-ng-template.directive';
import { Nullable } from 'primeng/ts-helpers';
import { KeyValuePipe, LowerCasePipe, NgTemplateOutlet } from '@angular/common';
import { ReplacePipe } from '@shared/components/replace.pipe';
import { InputNumberModule } from 'primeng/inputnumber';
import { NgModelChangeDebouncedDirective } from '@ui/ng-model-change-debounced.directive';

@Component({
  selector: 'number-range-input',
  styleUrls: ['number-range-input.component.scss'],
  templateUrl: 'number-range-input.component.html',
  standalone: true,
  imports: [
    CalendarModule,
    FormsModule,
    OverlayPanelModule,
    TranslateModule,
    NgTemplateOutlet,
    KeyValuePipe,
    LowerCasePipe,
    ReplacePipe,
    InputNumberModule,
    NgModelChangeDebouncedDirective,
  ],
})
export class NumberRangeInputComponent implements OnInit, OnChanges, AfterContentInit {
  @Input()
  public value: string | undefined | null;
  @Input()
  public decimal: boolean;
  @Output()
  public valueChange: EventEmitter<string> = new EventEmitter<string>();
  protected inputTemplate: TemplateRef<any> | undefined;
  protected readonly onInputClick = this.toggleNumberRangePicker.bind(this);
  protected minValue?: number;
  protected maxValue?: number;
  @ContentChildren(RobawsNgTemplateDirective)
  private templates: Nullable<QueryList<RobawsNgTemplateDirective>>;
  @ViewChild('numberRangePickerPanel')
  private numberRangePickerPanel: OverlayPanel;

  public ngOnInit(): void {
    this.updateNumberValue(this.value);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']) {
      this.updateNumberValue(changes['value'].currentValue);
    }
  }

  public ngAfterContentInit(): void {
    if (this.templates) {
      this.templates.forEach((template) => {
        if (template.type === 'input') {
          this.inputTemplate = template.template;
        }
      });
    }
  }

  protected toggleNumberRangePicker(event: MouseEvent): void {
    event.preventDefault();

    if (this.numberRangePickerPanel.overlayVisible) {
      this.numberRangePickerPanel.hide();
    } else {
      this.numberRangePickerPanel.show(event);
    }
  }

  protected onMinValueChange(event: number): void {
    this.minValue = event;
    this.updateValue();
  }

  protected onMaxValueChange(event: number): void {
    this.maxValue = event;
    this.updateValue();
  }

  private updateNumberValue(value: string | undefined | null): void {
    if (value) {
      const values = value.split(',');

      this.minValue = this.parseNumber(values[0]);

      if (values.length > 1) {
        this.maxValue = this.parseNumber(values[1]);
      } else {
        this.maxValue = undefined;
      }
    }
  }

  private parseNumber(str: string): number | undefined {
    return str === null || str === 'null' ? undefined : Number(str);
  }

  private updateValue(): void {
    this.value = `${this.minValue ?? null},${this.maxValue ?? null}`;
    this.valueChange.emit(this.value);
  }
}
