import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { View, ViewColumn, ViewContentType, ViewFilter, ViewSort } from '@app/robaws/domain';
import { PageDTO } from '@shared/domain';
import { ViewVisibilityType } from '@app/robaws/domain/ViewVisibilityType';
import { ViewSortCreateDTO } from '@app/robaws/domain/ViewSortCreateDTO';
import { map } from 'rxjs/operators';
import { ViewColumnCreateDTO } from '@app/robaws/domain/ViewColumnCreateDTO';
import { UserViewsInfo } from '@app/robaws/domain/UserViewsInfo';
import { ViewData } from '@app/robaws/domain/ViewData';
import { ViewType } from '@app/robaws/domain/ViewType';

const VIEW_INCLUDE: string = 'filterGroup,columns,sorts,parentView,additionalFilters';

@Injectable({
  providedIn: 'root',
})
export class ViewService {
  constructor(private httpClient: HttpClient) {}

  public getViewsWithContentType(
    contentType: ViewContentType,
    searchText: string = '',
    page: number,
    pageSize: number,
    sort: string,
  ): Observable<PageDTO<View>> {
    return this.fetchViews(contentType, {
      sort,
      searchText,
      size: pageSize,
      page,
    });
  }

  public copyView(view: View): Observable<View> {
    return this.httpClient.post<View>(
      '/api/v2/views',
      {
        name: view.name,
        contentType: view.contentType,
        visibility: 'PRIVATE',
        copyFromViewId: view.id,
      },
      {
        params: {
          include: VIEW_INCLUDE,
        },
      },
    );
  }

  public updateViewNameAndVisibility(viewId: string, name: string, visibility: ViewVisibilityType): Observable<void> {
    return this.patchView(viewId, { name, visibility });
  }

  public updateViewName(viewId: string, name: string): Observable<void> {
    return this.patchView(viewId, { name });
  }

  public updateViewVisibility(viewId: string, visibility: ViewVisibilityType): Observable<void> {
    return this.patchView(viewId, { visibility });
  }

  public deleteView(id: string): Observable<void> {
    return this.httpClient.delete<void>(`/api/v2/views/${id}`);
  }

  public updateLastUsedAt(viewId: string, date: Date): Observable<void> {
    return this.patchView(viewId, { lastUsedAt: date });
  }

  public updateViewType(viewId: string, viewType: ViewType): Observable<void> {
    return this.patchView(viewId, { type: viewType });
  }

  public getViewData(viewId: string, searchText: string, page: number, pageSize: number, overrideFilters: ViewFilter[]): Observable<ViewData> {
    return this.httpClient.get<ViewData>(`/api/v2/views/${viewId}/data`, {
      params: {
        searchText,
        page,
        size: pageSize,
        overrideFilters: JSON.stringify(overrideFilters),
      },
    });
  }

  public updateSorts(viewId: string, sorts: ViewSortCreateDTO[]): Observable<ViewSort[]> {
    return this.httpClient.put<ViewSort[]>(`/api/v2/views/${viewId}/sorts`, sorts).pipe(map((result: any) => result.items));
  }

  public updatePageSize(viewId: string, pageSize: number): Observable<void> {
    return this.patchView(viewId, { pageSize });
  }

  public updateColumns(viewId: string, columnCreateDTOs: ViewColumnCreateDTO[]): Observable<ViewColumn[]> {
    return this.httpClient.put<ViewColumn[]>(`/api/v2/views/${viewId}/columns`, columnCreateDTOs).pipe(map((result: any) => result.items));
  }

  public updateAdditionalFilters(viewId: string, additionalFilters: ViewFilter[]): Observable<ViewFilter[]> {
    return this.httpClient
      .put<ViewFilter[]>(`/api/v2/views/${viewId}/additional-filters`, additionalFilters)
      .pipe(map((result: any) => result.items));
  }

  public updateTableAndColumnWidths(viewId: string, tableWidth: number | null, columnWidths: string | null): Observable<void> {
    return this.patchView(viewId, { tableWidth, columnWidths });
  }

  public getUserViewsInfo(viewContentType: ViewContentType): Observable<UserViewsInfo> {
    return this.httpClient.get<UserViewsInfo>(`/api/v2/user-views-info/${viewContentType}`);
  }

  public updateUserViewsInfo(viewContentType: ViewContentType, userViewsInfo: UserViewsInfo): Observable<void> {
    return this.httpClient.post<void>(`/api/v2/user-views-info/${viewContentType}`, userViewsInfo);
  }

  public getViewsByIds(contentType: ViewContentType, ids: string[]): Observable<PageDTO<View>> {
    return this.fetchViews(contentType, {
      id: ids.join(','),
    });
  }

  public getViewsCreatedByCurrentUser(
    contentType: ViewContentType,
    searchText: string = '',
    page: number,
    pageSize: number,
    sort: string,
  ): Observable<PageDTO<View>> {
    return this.fetchViews(contentType, {
      searchText,
      page,
      size: pageSize,
      sort,
      createdByCurrentUser: true,
      hideViewsWithParentView: true,
    });
  }

  public getViewsCreatedByOtherUsers(
    contentType: ViewContentType,
    searchText: string = '',
    page: number,
    pageSize: number,
    sort: string,
  ): Observable<PageDTO<View>> {
    return this.fetchViews(contentType, {
      searchText,
      page,
      size: pageSize,
      sort,
      include: VIEW_INCLUDE + ',createdBy',
      createdByOtherUsers: true,
      hideViewsWithParentView: true,
    });
  }

  public createCopyWithParentView(view: View): Observable<View> {
    return this.httpClient.post<View>(
      '/api/v2/views',
      {
        name: view.name,
        contentType: view.contentType,
        visibility: 'PRIVATE',
        parentViewId: view.id,
      },
      {
        params: {
          include: VIEW_INCLUDE,
        },
      },
    );
  }

  public getPersonalSystemView(contentType: ViewContentType): Observable<View | undefined> {
    return this.fetchViews(contentType, {
      size: 1,
      onlyViewsWithParentSystemView: true,
    }).pipe(
      map((page) => {
        if (page.items.length === 0) {
          return undefined;
        } else {
          return page.items[0];
        }
      }),
    );
  }

  public getSystemView(contentType: ViewContentType): Observable<View | undefined> {
    return this.fetchViews(contentType, {
      size: 1,
      systemView: true,
    }).pipe(
      map((page) => {
        if (page.items.length === 0) {
          return undefined;
        } else {
          return page.items[0];
        }
      }),
    );
  }

  public findViewByParentViewId(contentType: ViewContentType, parentView: View): Observable<View | undefined> {
    return this.fetchViews(contentType, {
      size: 1,
      parentViewId: parentView.id,
      sort: 'id:asc',
    }).pipe(
      map((page) => {
        if (page.items.length === 0) {
          return undefined;
        } else {
          return page.items[0];
        }
      }),
    );
  }

  private patchView(id: string, patchBody: any): Observable<void> {
    return this.httpClient.patch<void>(`/api/v2/views/${id}`, patchBody, {
      headers: new HttpHeaders().append('Content-Type', 'application/merge-patch+json'),
    });
  }

  private fetchViews(contentType: ViewContentType, params: any): Observable<PageDTO<View>> {
    return this.httpClient.get<PageDTO<View>>('/api/v2/views', {
      params: {
        contentType,
        include: VIEW_INCLUDE,
        ...params,
      },
    });
  }
}
