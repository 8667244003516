import { WorkflowNode } from './WorkflowNode';
import { NodeDTO } from '@app/automation/domain';

export class CreateRobawsNotificationNode extends WorkflowNode {
  constructor(
    public title?: string,
    public content?: string,
    public userIds: string[] = [],
    public override nextNode?: NodeDTO<any>,
  ) {
    super(nextNode);
  }
}
