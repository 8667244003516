<ng-container *ngIf="sortedRowData(); let sorted">
  <td class="p-frozen-column" (click)="$event.stopPropagation()" [attr.style]="sorted.color ? 'background: ' + sorted.color : ''">
    <p-tableCheckbox [value]="rowData()" (click)="this.checkRangeSelect($event, rowIndex())"/>
  </td>
  <td *ngFor="let column of sorted.columns">
    @if (!!column.navigationDetails) {
      <strong (click)="navigate(column)">{{ column.value }}</strong>
    } @else {
      <span>{{ column.value }}</span>
    }
  </td>
</ng-container>
