import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { NgTemplateOutlet } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { RobawsNgSwitchComponent } from '@ui/robaws-ng-switch/robaws-ng-switch.component';
import { InputType } from '@shared/helpers';
import { NgModelChangeDebouncedDirective } from '@ui/ng-model-change-debounced.directive';
import { TooltipModule } from 'primeng/tooltip';
import { RobawsNgTemplateDirective } from '@shared/components/robaws-ng-template.directive';
import { Nullable } from 'primeng/ts-helpers';
import { AutoFocus } from 'primeng/autofocus';

@Component({
  selector: 'basic-input',
  styleUrls: ['basic-input.component.scss'],
  templateUrl: 'basic-input.component.html',
  standalone: true,
  imports: [
    CalendarModule,
    FormsModule,
    OverlayPanelModule,
    NgTemplateOutlet,
    InputTextModule,
    RobawsNgSwitchComponent,
    NgModelChangeDebouncedDirective,
    TooltipModule,
    AutoFocus,
  ],
})
export class BasicInputComponent implements AfterContentInit {
  @Input({ required: true })
  public inputType: InputType;

  @Input()
  public showLabel: boolean = true;

  @Input()
  public label: string | undefined | null = '';

  @Input()
  public openInDropdown: boolean = false;

  @Input()
  public value: string | undefined | null;

  @Output()
  public valueChange: EventEmitter<string> = new EventEmitter<string>();
  protected inputTemplate: TemplateRef<any> | undefined;
  protected readonly onInputClick = this.toggleOverlayPanel.bind(this);
  @ContentChildren(RobawsNgTemplateDirective)
  private templates: Nullable<QueryList<RobawsNgTemplateDirective>>;
  @ViewChild('simpleInputOverlayPanel')
  private simpleInputOverlayPanel: Nullable<OverlayPanel>;
  @ViewChild('inputField')
  private inputField: Nullable<ElementRef>;

  public ngAfterContentInit(): void {
    if (this.templates) {
      this.templates.forEach((template) => {
        if (template.type === 'input') {
          this.inputTemplate = template.template;
        }
      });
    }
  }

  protected onValueChange(value: string | boolean): void {
    if (typeof value === 'boolean') {
      value = value ? 'true' : 'false';
    }
    if (this.value === value) {
      return; // ignoring if the value is the same, especially important when the value was updated from a parent component
    }

    this.value = value ?? '';
    this.valueChange.emit(this.value);
  }

  protected getHtmlInputType(): string {
    switch (this.inputType) {
      default:
      case 'STRING':
        return 'text';
      case 'NUMBER':
      case 'DECIMAL':
        return 'number';
    }
  }

  protected getStep(): number | undefined {
    switch (this.inputType) {
      case 'DECIMAL':
        return 0.01;
      case 'NUMBER':
        return 1;
      default:
        return undefined;
    }
  }

  protected toggleOverlayPanel(event: MouseEvent): void {
    if (!this.simpleInputOverlayPanel) {
      return;
    }

    if (this.simpleInputOverlayPanel.overlayVisible) {
      this.simpleInputOverlayPanel.hide();
    } else {
      this.simpleInputOverlayPanel.show(event);

      if (this.inputField) {
        setTimeout(() => this.inputField?.nativeElement.focus(), 0);
      }
    }
  }
}
