import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { NgTemplateOutlet } from '@angular/common';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'date-input',
  templateUrl: 'date-input.component.html',
  standalone: true,
  imports: [CalendarModule, FormsModule, NgTemplateOutlet],
})
export class DateInputComponent implements OnInit {
  @Input()
  public label?: string | undefined | null;

  @Input()
  public value: string | undefined | null;

  @Input()
  public showLabel: boolean;

  @Input({ required: true })
  public isoFormat: boolean;

  @Output()
  public valueChange: EventEmitter<string> = new EventEmitter<string>();

  protected dateValue?: Date;
  protected uid: string = uuidv4();

  public ngOnInit(): void {
    if (this.value) {
      // this handles both the ISO format and the `yyyy-MM-dd` format.
      this.dateValue = new Date(this.value);
    }
  }

  protected onValueChange(event: Date): void {
    this.dateValue = event;

    this.value = event ? this.formatDate(event) : '';
    this.valueChange.emit(this.value);
  }

  private formatDate(date: Date): string {
    if (this.isoFormat) {
      return date.toISOString();
    }
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${date.getFullYear()}-${month}-${day}`;
  }
}
