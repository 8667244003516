import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, forkJoin, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { RobawsNgConfigurerCardService, UserService } from '@shared/services';
import { AbstractWorkflowNodeComponent } from '../../nodes/abstract-workflow-node-component';
import { WorkflowNodeData } from '../workflow-node/workflow-node-data';
import { CreateRobawsNotificationNodeConfigurerComponent } from '@app/automation/components/nodes/create-robaws-notification-node/create-robaws-notification-node-configurer/create-robaws-notification-node-configurer.component';
import { CreateRobawsNotificationNode } from '@app/automation/domain/nodes/CreateRobawsNotificationNode';
import { abbreviateTextWithEllipsis } from '@shared/helpers';
import { printXArrayElementsThenAbbreviate } from '@app/shared/helpers/array.helper';
import { map } from 'rxjs/operators';

@Component({
  selector: 'create-robaws-notification-node',
  templateUrl: 'create-robaws-notification-node.component.html',
})
export class CreateRobawsNotificationNodeComponent extends AbstractWorkflowNodeComponent implements OnDestroy, OnInit {
  public titleDisplayName$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public contentDisplayName$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public userDisplayNames$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  titleChangeSubscription: Subscription;
  contentChangeSubscription: Subscription;

  constructor(
    private userService: UserService,
    private robawsNgConfigurerCardService: RobawsNgConfigurerCardService,
    private translateService: TranslateService,
  ) {
    super();
  }

  private _workflowNodeData: WorkflowNodeData<CreateRobawsNotificationNode>;

  @Input({ required: true })
  public get workflowNodeData(): WorkflowNodeData<CreateRobawsNotificationNode> {
    return this._workflowNodeData;
  }

  public ngOnInit(): void {
    this.updateDisplayedUsernames(this.workflowNodeData.currentNode.configuration.userIds ?? '');
  }

  public set workflowNodeData(workflowNodeData: WorkflowNodeData<CreateRobawsNotificationNode>) {
    this._workflowNodeData = workflowNodeData;
    this.updateTitleDisplayName(this.workflowNodeData.currentNode.configuration.title ?? '');
    this.updateContentDisplayName(this.workflowNodeData.currentNode.configuration.content ?? '');
    this.updateDisplayedUsernames(this.workflowNodeData.currentNode.configuration.userIds ?? '');
  }

  public ngOnDestroy(): void {
    this.unsubscribe();
  }

  public openConfigurer(): void {
    this.unsubscribe();

    const currentConfigurer = this.robawsNgConfigurerCardService.createAndOpenConfigurerCard(CreateRobawsNotificationNodeConfigurerComponent, {
      title: this.translateService.instant('nodes.create-robaws-notification.title'),
      inputs: {
        currentNode: this.workflowNodeData.currentNode,
        resourceTypeMetadata: this.workflowNodeData.resourceTypeMetadata,
      },
    });

    this.titleChangeSubscription = currentConfigurer.contentRef.instance.onTitleChange.subscribe((title) => {
      this.updateTitleDisplayName(title);
    });

    this.contentChangeSubscription = currentConfigurer.contentRef.instance.onContentChange.subscribe((content) => {
      this.updateContentDisplayName(content);
    });

    currentConfigurer.contentRef.instance.onUsersChange.subscribe((users) => {
      this.updateDisplayedUsernames(users);
    });
  }

  private unsubscribe() {
    this.titleChangeSubscription?.unsubscribe();
    this.contentChangeSubscription?.unsubscribe();
  }

  private updateTitleDisplayName(title: string | null) {
    this.titleDisplayName$.next(abbreviateTextWithEllipsis(title ?? ''));
  }

  private updateContentDisplayName(content: string | null) {
    this.contentDisplayName$.next(abbreviateTextWithEllipsis(content ?? ''));
  }

  private updateDisplayedUsernames(users: string[]) {
    forkJoin(users.map((userId: string) => this.userService.findById(userId).pipe(map((user) => user.fullName)))).subscribe((userNames: string[]) => {
      this.userDisplayNames$.next(printXArrayElementsThenAbbreviate(userNames));
    });
  }
}
