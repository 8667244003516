import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { NgSelectModule } from '@ng-select/ng-select';

import { CheckboxModule } from 'primeng/checkbox';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';

import { DynamicTypeInputComponent } from '@app/shared/components/inputs/dynamic-type-input';

import { TriggerNodeComponent } from './trigger-node/trigger-node.component';
import { WorkflowNodeComponent } from './workflow-node/workflow-node.component';
import { AddNodeComponent } from './workflow-node/add-node/add-node.component';
import { AddNodeConfigurerComponent } from './workflow-node/add-node/add-node-configurer/add-node-configurer.component';
import { TriggerNodeConfigurerComponent } from './trigger-node/trigger-node-configurer/trigger-node-configurer.component';
import { TriggerNodeConditionListComponent } from './trigger-node/condition-list/trigger-node-condition-list.component';
import { UpdateRobawsResourceNodeComponent } from './update-robaws-resource-node/update-robaws-resource-node.component';
import { UpdateRobawsResourceNodeConfigurerComponent } from './update-robaws-resource-node/configurer/update-robaws-resource-node-configurer.component';
import { SendEmailNodeComponent } from './send-email-node/send-email-node.component';
import { SendEmailNodeConfigurerComponent } from './send-email-node/send-email-node-configurer/send-email-node-configurer.component';
import { FormatDataNodeComponent } from './format-data-node/format-data-node.component';
import { FormatDataNodeConfigurerComponent } from './format-data-node/format-data-node-configurer/format-data-node-configurer.component';
import { ExecuteRobawsResourceActionNodeComponent } from './execute-robaws-resource-action-node/execute-robaws-resource-action-node.component';
import { ExecuteRobawsResourceActionNodeConfigurerComponent } from './execute-robaws-resource-action-node/configurer/execute-robaws-resource-action-node-configurer.component';
import { TriggerNodeConditionViewComponent } from './trigger-node/condition-view/trigger-node-condition-view.component';
import { CopyValueNodeComponent } from './copy-value-node/copy-value-node.component';
import { CopyValueNodeConfigurerComponent } from './copy-value-node/copy-value-node-configurer/copy-value-node-configurer.component';
import { DelayNodeComponent } from './delay-node/delay-node.component';
import { DelayNodeConfigurerComponent } from './delay-node/delay-node-configurer/delay-node-configurer.component';
import { SendTextMessageNodeComponent } from './send-text-message-node/send-text-message-node.component';
import { SendTextMessageNodeConfigurerComponent } from './send-text-message-node/send-text-message-node-configurer/send-text-message-node-configurer.component';
import { TriggerNodeConditionBuilderComponent } from './trigger-node/condition-builder/trigger-node-condition-builder.component';
import { CreateRobawsResourceNodeComponent } from './create-robaws-resource-node/create-robaws-resource-node.component';
import { CreateRobawsResourceNodeConfigurerComponent } from './create-robaws-resource-node/configurer/create-robaws-resource-node-configurer.component';
import { DelayNodeSuspendedExecutionsConfigurerComponent } from '@app/automation/components/nodes/delay-node/delay-node-suspended-executions-configurer/delay-node-suspended-executions-configurer.component';
import { TableModule } from 'primeng/table';
import { AutomationOperationBuilderComponent } from '@app/automation/components/automation-operation-builder/automation-operation-builder.component';
import { PropertyPathSelectorComponent } from '@shared/components/property-path-selector';
import { TimeDiffPipe } from '@shared/components/time-diff.pipe';
import { MaterialLoaderDirective } from '@ui/material-loader/material-loader.directive';
import { PrimaryColorDirective } from '@ui/theme/primary-color.directive';
import { RobawsNgInfoComponent } from '@ui/robaws-ng-info/robaws-ng-info.component';
import { TreeSelectComponent } from '@ui/tree-select/tree-select.component';
import { RobawsNgCardComponent } from '@ui/robaws-ng-card/robaws-ng-card.component';
import { RobawsNgCardHeaderComponent } from '@ui/robaws-ng-card/robaws-ng-card-header/robaws-ng-card-header.component';
import { RobawsNgCardTitleComponent } from '@ui/robaws-ng-card/robaws-ng-card-title/robaws-ng-card-title.component';
import { RobawsNgCardContentComponent } from '@ui/robaws-ng-card/robaws-ng-card-content/robaws-ng-card-content.component';
import { DeleteIconComponent } from '@ui/delete-icon/delete-icon.component';
import { CreateRobawsNotificationNodeComponent } from '@app/automation/components/nodes/create-robaws-notification-node/create-robaws-notification-node.component';
import { CreateRobawsNotificationNodeConfigurerComponent } from '@app/automation/components/nodes/create-robaws-notification-node/create-robaws-notification-node-configurer/create-robaws-notification-node-configurer.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    MatIconModule,
    TranslateModule,
    MatButtonModule,
    NgSelectModule,

    InputSwitchModule,
    DropdownModule,
    InputNumberModule,
    RadioButtonModule,
    CheckboxModule,
    InputTextareaModule,
    InputTextModule,
    MultiSelectModule,

    RobawsNgInfoComponent,
    TreeSelectComponent,

    TableModule,
    PropertyPathSelectorComponent,
    AutomationOperationBuilderComponent,
    DynamicTypeInputComponent,
    TimeDiffPipe,
    MaterialLoaderDirective,
    PrimaryColorDirective,
    RobawsNgCardComponent,
    RobawsNgCardHeaderComponent,
    RobawsNgCardTitleComponent,
    RobawsNgCardContentComponent,
    DeleteIconComponent,
  ],
  exports: [TriggerNodeComponent],
  declarations: [
    TriggerNodeComponent,
    WorkflowNodeComponent,
    AddNodeComponent,
    AddNodeConfigurerComponent,
    TriggerNodeConfigurerComponent,
    TriggerNodeConditionListComponent,
    TriggerNodeConditionBuilderComponent,
    TriggerNodeConditionViewComponent,
    UpdateRobawsResourceNodeComponent,
    UpdateRobawsResourceNodeConfigurerComponent,
    SendEmailNodeComponent,
    SendEmailNodeConfigurerComponent,
    FormatDataNodeComponent,
    FormatDataNodeConfigurerComponent,
    ExecuteRobawsResourceActionNodeComponent,
    ExecuteRobawsResourceActionNodeConfigurerComponent,
    CopyValueNodeComponent,
    CopyValueNodeConfigurerComponent,
    DelayNodeComponent,
    DelayNodeConfigurerComponent,
    DelayNodeSuspendedExecutionsConfigurerComponent,
    SendTextMessageNodeComponent,
    SendTextMessageNodeConfigurerComponent,
    CreateRobawsResourceNodeComponent,
    CreateRobawsResourceNodeConfigurerComponent,
    CreateRobawsNotificationNodeComponent,
    CreateRobawsNotificationNodeConfigurerComponent,
  ],
})
export class NodesModule {}
