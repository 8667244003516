import { MetadataProviderType, ResourceTypeMetadata } from '@shared/domain';
import { ResourceTypeProvider } from '@app/shared/services/resource-type-provider';
import { AUTOMATION_RESOURCE_TYPE_MAPPER, AutomationResourceTypeService } from '@app/automation/services';
import { ROBAWS_RESOURCE_TYPE_MAPPER, RobawsResourceTypeService } from '@app/robaws/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { inject, runInInjectionContext } from '@angular/core';
import { AppInjector } from '@app/app.module';

export class DynamicResourceTypeProvider implements ResourceTypeProvider<ResourceTypeMetadata> {
  private robawsResourceTypeService: RobawsResourceTypeService;
  private automationResourceTypeService: AutomationResourceTypeService;

  constructor(private metadataProviderType: MetadataProviderType) {
    runInInjectionContext(AppInjector, () => {
      this.robawsResourceTypeService = inject(RobawsResourceTypeService);
      this.automationResourceTypeService = inject(AutomationResourceTypeService);
    });
  }

  public getMetadata(name: string): Observable<ResourceTypeMetadata> {
    switch (this.metadataProviderType) {
      case 'AUTOMATION':
        return this.automationResourceTypeService.getMetadata(name).pipe(map((metadata) => AUTOMATION_RESOURCE_TYPE_MAPPER.mapMetadata(metadata)));
      case 'ROBAWS':
        return this.robawsResourceTypeService.getMetadata(name).pipe(map((metadata) => ROBAWS_RESOURCE_TYPE_MAPPER.mapMetadata(metadata)));
      case 'VIEW':
        return this.robawsResourceTypeService.getMetadata(name, 'VIEW').pipe(map((metadata) => ROBAWS_RESOURCE_TYPE_MAPPER.mapMetadata(metadata)));
    }
  }
}
