<workflow-node (nodeClick)="openConfigurer()" [workflowNodeData]="workflowNodeData">

  <span slot="title">{{ 'nodes.create-robaws-notification.description.default' | translate }}</span>

  <div slot="content">
    <div
      *ngIf="workflowNodeData.currentNode.configuration.title && workflowNodeData.currentNode.configuration.content &&
      workflowNodeData.currentNode.configuration.userIds.length > 0">
      <p class="description"
         [innerHTML]="'nodes.create-robaws-notification.description.detailed' | translate: {
        'title': titleDisplayName$ | async,
        'content': contentDisplayName$ | async,
        'users': userDisplayNames$ | async
       }">
      </p>
    </div>
    <p
      *ngIf="!workflowNodeData.currentNode.configuration.title || !workflowNodeData.currentNode.configuration.content ||
      workflowNodeData.currentNode.configuration.userIds.length === 0"
      class="description">
      {{ 'nodes.create-robaws-notification.description.default' | translate }}
    </p>
  </div>
</workflow-node>
