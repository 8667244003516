<div class="robaws-ng-select-container">

  <ng-container [ngTemplateOutlet]="inputTemplate || builtinInputTemplate"
                [ngTemplateOutletContext]="{ $implicit: this.onInputClick }" />

  <ng-template #builtinInputTemplate let-onClick>
    <div class="input-container" (click)="onClick($event)">
      @if (this.showPlaceholder && this.placeholder && this.placeholder.length > 0) {
        <span class="p-float-label">
          <ng-container *ngTemplateOutlet="inputContainerTemplate"/>

          <label [htmlFor]="this.uid">{{ this.placeholder }}</label>
        </span>
      } @else {
        <ng-container *ngTemplateOutlet="inputContainerTemplate" />
      }
    </div>
  </ng-template>

  <ng-template #inputContainerTemplate>
    <input autocapitalize="off"
           autocomplete="off"
           readonly="readonly"
           class="select-input-field"
           [id]="this.uid"
           [ngModel]="this.selectedValuesLabel" />

    <mat-icon fontIcon="close_small" class="clear-icon" (click)="this.clear($event, true)" *ngIf="this.inputClearIcon" />
    <mat-icon fontIcon="arrow_drop_down" class="dropdown-icon" />
  </ng-template>

  <p-overlayPanel #dropdownPanel
                  [appendTo]="this.appendTo"
                  styleClass="robaws-ng-select-dropdown">

    <div class="dropdown-container"
         [ngStyle]="this.style"
         [materialLoader]="this.loading">

      <div class="filter-container" *ngIf="this.showFilter">
        <input type="text"
               class="filter-input"
               placeholder="Filter"
               pInputText
               [ngModel]="this.filter"
               (ngModelChangeDebounced)="this.onFilterChanged($event)"
               #filterInput />

        <mat-icon fontIcon="close_small" (click)="this.clear($event, true)" style="color: #757575; cursor: pointer;" />
      </div>

      <div class="scrollpanel" (scroll)="this.onScroll()" #scrollPanel [attr.data-show-filter]="this.showFilter">
        @for (item of this.items; track item[this.optionValue]) {
          <div class="select-item p-3" (click)="this.onItemClick(item)">
            @if (this.multiple) {
              <p-checkbox [ngModel]="this.isSelected(item)"
                          [label]="item[this.optionLabel]"
                          [binary]="true"
                          [style]="{ 'margin-right': '10px' }" />
            } @else {
              <span>{{ item[this.optionLabel] }}</span>
            }
          </div>
        }
      </div>
    </div>
  </p-overlayPanel>
</div>
