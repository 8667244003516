<property-path-selector (propertySelected)="handlePropertySelect($event)"
                        *ngIf="this.propertyPathBuilderData"
                        [metadataProviderType]="metadataProviderType"
                        [data]="this.propertyPathBuilderData"
                        [includeReadOnly]="false" />

<dynamic-type-input *ngIf="this.pathBuilderData && this.pathBuilderData.path"
                    [value]="this.patchNode.value"
                    [label]="'Value'"
                    [metadataProviderType]="metadataProviderType"
                    [metadata]="this.currentMetadata"
                    (valueChange)="handleValueChange($event)"
                    [inputData]="{ dataType: this.pathBuilderData.dataType, expectedInputTypeMetadata: this.expectedMetadata, possibleValues: this.pathBuilderData.possibleValues }"
                    [inputType]="this.pathBuilderData.inputType" />
