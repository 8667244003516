import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ResourceTypeMapper, ResourceTypeProvider } from '@app/shared/services';
import {
  ResourceTypeAction,
  ResourceTypeActionParameter,
  ResourceTypeActionParameterPossibleValue,
  ResourceTypeAttribute,
  ResourceTypeEmailTemplate,
  ResourceTypeEvent,
  ResourceTypeExtraField,
  ResourceTypeLink,
  ResourceTypeMetadata,
  ResourceTypePropertyPossibleValue,
} from '@app/shared/domain';

import {
  RobawsResourceTypeAction,
  RobawsResourceTypeActionParameter,
  RobawsResourceTypeActionParameterPossibleValue,
  RobawsResourceTypeAttribute,
  RobawsResourceTypeEmailTemplate,
  RobawsResourceTypeEvent,
  RobawsResourceTypeExtraField,
  RobawsResourceTypeLink,
  RobawsResourceTypeMetadata,
  RobawsResourceTypePropertyPossibleValue,
  RobawsResourceTypeWithDetails,
} from '@app/robaws/domain';
import { withCache } from '@ngneat/cashew';
import { filter, map } from 'rxjs/operators';
import { concatIfEmpty } from '@app/shared/helpers/rxjs.helper';
import { MetadataPurpose } from '@app/robaws/domain/MetadataPurpose';

@Injectable({
  providedIn: 'root',
})
export class RobawsResourceTypeService implements ResourceTypeProvider<RobawsResourceTypeMetadata> {
  constructor(private httpClient: HttpClient) {}

  public getResourceTypes(purpose: MetadataPurpose = 'DEFAULT'): Observable<RobawsResourceTypeWithDetails[]> {
    return this.httpClient.get<RobawsResourceTypeWithDetails[]>('/api/v2/resource-types?includeDetails=true', {
      context: withCache(),
      params: {
        purpose,
      },
    });
  }

  public getMetadata(name: string, purpose: MetadataPurpose = 'DEFAULT'): Observable<RobawsResourceTypeMetadata> {
    const fallBack = this.httpClient.get<RobawsResourceTypeMetadata>('/api/v2/resource-types/' + name, {
      context: withCache(),
      params: {
        purpose,
      },
    });
    return this.getResourceTypes(purpose).pipe(
      map((resourceTypes) => {
        return resourceTypes.find((resourceType) => resourceType.id === name)?.details;
      }),
      filter((metadata): metadata is RobawsResourceTypeMetadata => !!metadata),
      concatIfEmpty(fallBack),
    );
  }
}

export class RobawsResourceTypeMapper implements ResourceTypeMapper<RobawsResourceTypeMetadata> {
  public mapMetadata(metadata: RobawsResourceTypeMetadata): ResourceTypeMetadata {
    return {
      name: metadata.type,
      displayName: metadata.name,
      basePath: metadata.basePath,
      attributes: metadata.attributes.map((item) => {
        return this.mapAttribute(item);
      }),
      extraFields: metadata.extraFields.map((item) => {
        return this.mapExtraField(item);
      }),
      links: metadata.links.map((item) => {
        return this.mapLink(item);
      }),
      events: metadata.events.map((item) => {
        return this.mapEvent(item);
      }),
      actions: metadata.actions.map((item) => {
        return this.mapAction(item);
      }),
      emailTemplates:
        metadata.emailTemplates?.map((item) => {
          return this.mapEmailTemplate(item);
        }) || [],
    };
  }

  private mapAttribute(attribute: RobawsResourceTypeAttribute): ResourceTypeAttribute {
    return {
      name: attribute.id,
      displayName: attribute.name,
      dataType: attribute.dataType,
      readOnly: attribute.readOnly,
      sortable: attribute.sortable,
      primary: attribute.primary,
      possibleValues: attribute.possibleValues
        ? attribute.possibleValues.map((item) => {
            return this.mapPossibleValue(item);
          })
        : [],
      attributes:
        attribute.attributes?.map((item) => {
          return this.mapAttribute(item);
        }) || [],
      availableOperators: attribute.availableOperators,
    };
  }

  private mapExtraField(extraField: RobawsResourceTypeExtraField): ResourceTypeExtraField {
    return {
      name: extraField.id,
      displayName: extraField.label,
      dataType: extraField.dataType,
      sortable: extraField.sortable,
      possibleValues: extraField.possibleValues
        ? extraField.possibleValues.map((item) => {
            return this.mapPossibleValue(item);
          })
        : [],
      group: extraField.group,
    };
  }

  private mapPossibleValue(possibleValue: RobawsResourceTypePropertyPossibleValue): ResourceTypePropertyPossibleValue {
    return {
      name: possibleValue.name,
      value: possibleValue.value,
    };
  }

  private mapLink(link: RobawsResourceTypeLink): ResourceTypeLink {
    return {
      name: link.id,
      displayName: link.name,
      resource: link.targetResourceTypeId,
      type: link.type,
      foreignKey: link.foreignKey,
      availableOperators: link.availableOperators,
    };
  }

  private mapEvent(event: RobawsResourceTypeEvent): ResourceTypeEvent {
    return {
      name: event.id,
      type: event.type,
    };
  }

  private mapAction(action: RobawsResourceTypeAction): ResourceTypeAction {
    return {
      name: action.id,
      displayName: action.name,
      method: action.method,
      resourceRelativePath: action.resourceRelativePath,
      createdResourceTypeId: action.createdResourceTypeId,
      parameters:
        action.parameters?.map((item) => {
          return this.mapParameter(item);
        }) || [],
    };
  }

  private mapParameter(parameter: RobawsResourceTypeActionParameter): ResourceTypeActionParameter {
    return {
      name: parameter.id,
      displayName: parameter.name,
      dataType: parameter.dataType,
      possibleValues:
        parameter.possibleValues?.map((item) => {
          return this.mapParameterPossibleValue(item);
        }) || [],
    };
  }

  private mapParameterPossibleValue(possibleValue: RobawsResourceTypeActionParameterPossibleValue): ResourceTypeActionParameterPossibleValue {
    return {
      name: possibleValue.name,
      value: possibleValue.value,
    };
  }

  private mapEmailTemplate(emailTemplate: RobawsResourceTypeEmailTemplate): ResourceTypeEmailTemplate {
    return {
      name: emailTemplate.id,
      displayName: emailTemplate.name,
    };
  }
}

export const ROBAWS_RESOURCE_TYPE_MAPPER = new RobawsResourceTypeMapper();
