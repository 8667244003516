import { Observable, of } from 'rxjs';

import { WorkflowNodeType } from '@app/automation/domain';

import { WorkflowNodeCategoryType, WorkflowNodeTypeVO } from '../../add-node-configurer/add-node-configurer.component';
import { NodeProvider } from '../node-provider';

export const createRobawsNotificationNodeProvider: NodeProvider = (): Observable<WorkflowNodeTypeVO[]> => {
  return of([
    {
      type: WorkflowNodeType.create_robaws_notification,
      category: WorkflowNodeCategoryType.ACTION,
      icon: 'circle_notifications',
      color: 'darkorange',
      priority: 2,
    },
  ]);
};
