import { Component, DestroyRef, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AbstractWebComponent } from '@shared/components/abstract-web.component';
import { View, ViewContentType } from '@app/robaws/domain';
import { AutoFocus } from 'primeng/autofocus';
import { DeleteIconComponent } from '@ui/delete-icon/delete-icon.component';
import { MatIcon } from '@angular/material/icon';
import { PropertyPathSelectorComponent } from '@shared/components/property-path-selector';
import { DynamicResourceTypeProvider } from '@app/shared/services/dynamic-resource-type.provider';
import { InternalServiceMessageEvent, InternalServiceMessageService, Path, PathService } from '@shared/services';
import { ViewSettingsComponent } from '@app/robaws/components/dynamic-overview/view-settings/view-settings.component';
import { ViewService } from '@app/robaws/services/view.service';
import { filter, switchMap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';
import { ViewQuickFiltersService } from '@app/robaws/services/view-quick-filters.service';
import { ResourceTypeMetadata } from '@shared/domain';
import { RobawsConstants } from '@app/robaws/domain/RobawsConstants';

@Component({
  selector: 'dynamic-overview-settings',
  templateUrl: 'dynamic-overview-settings.component.html',
  styleUrls: ['dynamic-overview-settings.component.scss'],
  standalone: true,
  imports: [
    AutoFocus,
    DeleteIconComponent,
    MatIcon,
    PropertyPathSelectorComponent,
    ViewSettingsComponent,
    CdkDrag,
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    TranslateModule,
  ],
})
export class DynamicOverviewSettingsComponent extends AbstractWebComponent implements OnInit, OnDestroy {
  @Input({ required: true })
  public viewContentType: ViewContentType;

  @Input({ required: true })
  public resourceType: string;
  protected systemView?: View;
  protected metadataPaths: Path[] = [];
  protected quickFilterPaths: string[] = [];
  protected metadata: ResourceTypeMetadata;
  @ViewChild(ViewSettingsComponent)
  private viewSettingsComponent: ViewSettingsComponent;
  private dynamicResourceTypeProvider: DynamicResourceTypeProvider = new DynamicResourceTypeProvider('VIEW');
  private quickFiltersChanged: boolean = false;

  constructor(
    protected override viewContainerRef: ViewContainerRef,
    private pathService: PathService,
    private viewService: ViewService,
    private viewQuickFiltersService: ViewQuickFiltersService,
    private internalServiceMessageService: InternalServiceMessageService,
    private destroyRef: DestroyRef,
  ) {
    super(viewContainerRef);
  }

  public ngOnInit(): void {
    this.dynamicResourceTypeProvider.getMetadata(this.resourceType).subscribe((metadata) => (this.metadata = metadata));
    this.viewQuickFiltersService.getQuickFilterPaths(this.viewContentType).subscribe((paths) => {
      this.quickFilterPaths = paths;
      this.quickFiltersChanged = false;
    });
    this.pathService
      .getPaths(this.dynamicResourceTypeProvider, this.resourceType, false, true, true, true, true, true)
      .subscribe((paths) => (this.metadataPaths = paths));
    this.viewService.getSystemView(this.viewContentType).subscribe((view) => (this.systemView = view));

    this.internalServiceMessageService
      .onMessageReceive('any')
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((it) => it.action === 'config-save' && it.data === this.viewContentType.toLowerCase().replace('_', '-') + '-dynamic-overview-config'),
        switchMap(() => this.save()),
      )
      .subscribe(() => {
        this.internalServiceMessageService.send(
          new InternalServiceMessageEvent(
            'robaws-ng',
            'robaws',
            'config-saved',
            this.viewContentType.toLowerCase().replace('_', '-') + '-dynamic-overview-config',
          ),
        );
      });
  }

  public ngOnDestroy(): void {
    if (!this.systemView) {
      return;
    }

    this.save().subscribe();
  }

  protected handleQuickFilterPathSelected(index: number, path: string): void {
    this.quickFilterPaths[index] = path;
    this.quickFiltersChanged = true;
  }

  protected addQuickFilterPath(): void {
    if (this.quickFilterPaths.length >= 10) {
      return;
    }
    this.quickFilterPaths.push('');
    this.quickFiltersChanged = true;
  }

  protected deleteQuickFilterPath(index: number): void {
    this.quickFilterPaths.splice(index, 1);
    this.quickFiltersChanged = true;
  }

  protected filterAdditionalViewDataPaths(path: Path): boolean {
    if (path.path.includes(RobawsConstants.ADDITIONAL_VIEW_FILTER_PREFIX)) {
      const part = path.path.split(RobawsConstants.ADDITIONAL_VIEW_FILTER_PREFIX)[1];

      // if the part after the additional filter prefix contains a dot, we want to skip it
      if (part.indexOf('.') > 0) {
        return false;
      }
    }
    return !path.path.includes(RobawsConstants.ADDITIONAL_VIEW_DATA_PREFIX);
  }

  private save(): Observable<any> {
    if (!this.systemView) {
      console.warn('System view is not loaded yet');
      return EMPTY;
    }
    const settings = this.viewSettingsComponent.getCurrentSettings();
    const observables = [];

    if (settings.name !== this.systemView.name) {
      observables.push(this.viewService.updateViewName(this.systemView.id, settings.name));
    }

    if (
      settings.columns.length !== this.systemView.columns.length ||
      settings.columns.join(',') !== this.systemView.columns.map((column) => column.dataPath).join(',')
    ) {
      observables.push(
        this.viewService.updateColumns(
          this.systemView.id,
          settings.columns.map((it) => ({ dataPath: it })),
        ),
      );
    }

    if (this.quickFiltersChanged) {
      const paths = this.quickFilterPaths.filter((path) => path !== '');

      observables.push(this.viewQuickFiltersService.updateQuickFilterPaths(this.viewContentType, paths));
    }

    if (observables.length === 0) {
      return of({});
    } else {
      return forkJoin(observables);
    }
  }
}
