<robaws-ng-dialog [header]="'overviews.settings.title' | translate"
                  (onHide)="this.onHide()"
                  #dialog>

  <view-settings [view]="this.view"
                 [paths]="this.paths"
                 [metadata]="this.metadata"
                 (viewDeleted)="this.onViewDeleted($event)"/>

</robaws-ng-dialog>
